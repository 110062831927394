import { useState } from 'react';
import emailjs from 'emailjs-com';
import Location from '../../assets/Icons/Location';
import Phone from '../../assets/Icons/Phone';
import Work from '../../assets/Icons/Work';
import Title from '../../components/Title';

const ContacusForm = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    name: '',
    designation: '',
    email: '',
    mobile: '',
    message: '',
  });

  const [errors, setErrors] = useState<any>({
    companyName: '',
    name: '',
    designation: '',
    email: '',
    mobile: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const errors: any = {}; // Explicitly typed as `any` to avoid errors
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (!formData.companyName) errors.companyName = 'Company Name is required';
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.designation) errors.designation = 'Designation is required';
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      errors.email = 'Invalid email format';
    }
    if (!formData.mobile) {
      errors.mobile = 'Mobile number is required';
    } else if (!phoneRegex.test(formData.mobile)) {
      errors.mobile = 'Invalid mobile number format (must be 10 digits)';
    }
    if (!formData.message) errors.message = 'Message is required';

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return; // If validation fails, do not proceed
    }

    setIsSubmitting(true);
    try {
      const result = await emailjs.send(
        'service_d5dgjc4', // Replace with your EmailJS service ID
        'template_9027q0q', // Replace with your EmailJS template ID
        formData,
        '-wYJ4TImew-7jPcvR' // Replace with your EmailJS user ID
      );
      if (result.status === 200) {
        alert('Form submitted successfully');
        setFormData({
          companyName: '',
          name: '',
          designation: '',
          email: '',
          mobile: '',
          message: '',
        });
      } else {
        alert('Failed to submit form');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while sending the email');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-primary md:flex py-[60px] md:px-24 justify-center gap-20 rounded-xl md:w-[1024px] md:mx-auto items-center mt-8">
      <div>
        <div className="w-fit mx-auto">
          <Title text="For More Details" color="text-white" />
          <Title text="Contact Us" weight="font-bold" color="text-white" />
        </div>
        <br />
        <div className="mx-0 flex flex-col md:gap-2 items-center text-sm md:text-md text-start justify-start">
          <div>
            <div className="flex items-center gap-2 mt-4 text-white w-fit text-start md:mx-0">
              <div className="mx-auto w-fit">
                <Phone />
              </div>
              <div className="flex flex-col">
                <span className="font-medium">PHONE</span>
                <span>040-67833412</span>
              </div>
            </div>

            <div className="flex items-center gap-2 mt-4 text-white w-fit text-start md:mx-0">
              <div className="mx-auto w-fit">
                <Work />
              </div>
              <div className="flex flex-col">
                <div className="font-medium">EMAIL</div>
                <div className="mb-2">info@agiplc.com</div>
                <div className="font-medium">ALTERNATE EMAIL</div>
                <div>agiplcindia@gmail.com</div>
              </div>
            </div>
            <div className="flex items-center gap-2 mt-4 text-white w-fit text-start md:mx-0">
              <div className="mx-auto w-fit">
                <Location />
              </div>
              <div className="flex flex-col">
                <span className="font-medium">ADDRESS</span>
                <span>Office #841, Regus Business Centre, </span>
                <span>SLN Terminus Building, Gachibowli</span>
                <span>Hyderabad, Telangana 500032</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-72 mx-auto">
        <div className="flex flex-col mt-4 w-76">
          <label className="font-semibold text-white">Company Name:</label>
          <input
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="bg-white bg-opacity-30 rounded-md h-8 text-primary px-2"
          />
          {errors.companyName && (
            <span className="text-red-500 text-sm">{errors.companyName}</span>
          )}
        </div>
        <div className="flex gap-3">
          <div className="flex flex-col mt-4 w-36">
            <label className="font-semibold text-white">Name:</label>
            <input
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="bg-white bg-opacity-30 rounded-md h-8 text-primary px-2"
            />
            {errors.name && (
              <span className="text-red-500 text-sm">{errors.name}</span>
            )}
          </div>
          <div className="flex flex-col mt-4 w-[132px]">
            <label className="font-semibold text-white">Designation:</label>
            <input
              name="designation"
              value={formData.designation}
              onChange={handleChange}
              className="bg-white bg-opacity-30 rounded-md h-8 text-primary px-2"
            />
            {errors.designation && (
              <span className="text-red-500 text-sm">{errors.designation}</span>
            )}
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <label className="font-semibold text-white">Email:</label>
          <input
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="bg-white bg-opacity-30 rounded-md h-8 text-primary px-2"
          />
          {errors.email && (
            <span className="text-red-500 text-sm">{errors.email}</span>
          )}
        </div>
        <div className="flex flex-col mt-4">
          <label className="font-semibold text-white">Mobile Number:</label>
          <input
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            className="bg-white bg-opacity-30 rounded-md h-8 text-primary px-2"
          />
          {errors.mobile && (
            <span className="text-red-500 text-sm">{errors.mobile}</span>
          )}
        </div>
        <div className="flex flex-col mt-4">
          <label className="font-semibold text-white">Message:</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="bg-white bg-opacity-30 rounded-md h-36 text-primary px-2"
          />
          {errors.message && (
            <span className="text-red-500 text-sm">{errors.message}</span>
          )}
        </div>
        <button
          className="bg-white text-primary font-semibold h-8 rounded-md mt-4 flex justify-center items-center cursor-pointer w-full"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default ContacusForm;
