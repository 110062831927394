import React from 'react';
import Title from '../../components/Title';
import photo1 from '../../assets/Photos/Sudipto.png';
import Linkedin from '../../assets/Icons/Linkedin';
import CaptainTanwar from '../../assets/Photos/CaptainTanwarReal.png';
import TimArcher from '../../assets/Photos/Tim Archer.png';
import David from '../../assets/Photos/David.png';
import PageHeading from '../../components/PageHeading/PageHeading';
import Subtitle from '../../components/Subtitle';

function Leadership() {
  return (
    <div className="mb-12 font-sans">
      <div className="mx-4 mt-16 md:mx-64">
        {/* <Title
          text="LEADERSHIP TEAM"
          size={44}
          color="text-gray-700"
          weight={'font-bold'}
        /> */}
        <div className="flex flex-col md:flex-row border border-1 rounded-xl p-6 gap-4 mt-4 w-[100%] justify-center">
          <div className="px-4 md:w-[30%]">
            <div className="mb-2">
              <img
                loading="lazy"
                src={photo1}
                className="leadership-team-img"
              />
            </div>
            <div>
              <div className="flex flex-row justify-between">
                <div>
                  <div>
                    <Title
                      text="Sudipto Mukerji"
                      size={10}
                      color="#19191B"
                      weight="font-semibold"
                    />
                  </div>
                  <div className="max-w-md">
                    <Subtitle
                      text="Founder Promoter and Managing Director"
                      size={16}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="font-medium md:w-[70%] text-[12px] md:text-[18px]">
            <ul className="list-disc pl-6">
              <li>Geoscience Entrepreneur</li>
              <li>
                Promoter of the first Indian private airborne geophysical survey
                company
              </li>
              <li>
                More than 20 years of international exploration experience
              </li>
              <li>
                Pioneer of the National Airborne Geophysical Mapping Project in
                India for exploration of base metals and minerals
              </li>
              <li>
                Global Airborne Survey management experience of more than 2
                Million Line kms with a large focus on India
              </li>
            </ul>
          </div>
        </div>

        {/* PERSON 2 */}
        <div className="flex flex-col md:flex-row border border-1 rounded-xl p-6 gap-4 mt-4 w-[100%] justify-center">
          <div className="px-4 md:w-[30%]">
            <div className="mb-2">
              <img loading="lazy" src={CaptainTanwar} />
            </div>
            <div>
              <div className="flex flex-row justify-between">
                <div>
                  <div>
                    <Title
                      text="Captain Anirudh Tanwar"
                      size={10}
                      color="#19191B"
                      weight="font-semibold"
                    />
                  </div>
                  <div className="max-w-md">
                    <Subtitle
                      text="Founder Promoter and Director - Aviation"
                      size={16}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="font-medium md:w-[70%] text-[12px] md:text-[18px]">
            <ul className="list-disc pl-6">
              <li>
                Director of M/S Sarthi Airways Private Limited one of the
                largest providers of air-chartering services of India.
              </li>
              <li>
                Company authorized to operate both Indian and foreign registered
                aircraft in India under their Aircraft Operating Certificate
                (AOC)
              </li>
              <li>
                Excellent track record with the Directorate General of Civil
                Aviation (DGCA), which will help AGI facilitate survey permits
                and clearances
              </li>
            </ul>
          </div>
        </div>

        {/* PERSON 3 */}
        <div className="flex flex-col md:flex-row border border-1 rounded-xl  p-6 gap-4 mt-4 w-[100%] justify-center">
          <div className="px-4 md:w-[30%]">
            <div className="mb-2">
              <img
                loading="lazy"
                src={TimArcher}
                className="leadership-team-img"
              />
            </div>
            <div>
              <div className="flex flex-row justify-between">
                <div>
                  <div>
                    <Title
                      text="Tim Archer"
                      size={10}
                      color="#19191B"
                      weight="font-semibold"
                    />
                  </div>
                  <div>
                    <Subtitle text="Chief Technology Officer" size={16} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="font-medium md:w-[70%] text-[12px] md:text-[18px]">
            <ul className="list-disc pl-6">
              <li>
                Well-reputed commercial geophysicist with 25+ years of
                international experience
              </li>
              <li>
                Technical expertise in a wide range of geophysical techniques
                for natural resource exploration
              </li>
              <li>
                Work experience in diverse cultures on six continents for a
                range of mineral targets in various geological settings
              </li>
              <li>
                Currently runs a highly acclaimed and bespoke geophysical
                consulting company out of the UK
              </li>
            </ul>
          </div>
        </div>

        {/* PERSON 4 */}
        <div className="flex flex-col md:flex-row border border-1 rounded-xl  p-6 gap-4 mt-4 w-[100%] justify-center">
          <div className="px-4 md:w-[30%]">
            <div className="mb-2">
              <img loading="lazy" src={David} className="leadership-team-img" />
            </div>
            <div>
              <div className="flex flex-row justify-between">
                <div>
                  <div>
                    <Title
                      text="David Ovadia MBE"
                      size={10}
                      color="#19191B"
                      weight="font-semibold"
                    />
                  </div>
                  <div>
                    <Subtitle text="Principal Management Advisor" size={16} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="font-medium md:w-[70%] text-[12px] md:text-[18px]">
            <ul className="list-disc pl-6">
              <li>
                40+ years of mineral exploration experience across diverse
                cultures and countries
              </li>
              <li>Ex-Director of British Geological Survey</li>
              <li>
                Honored with the MBE by Her Majesty Queen Elizabeth II for
                outstanding contributions to the global mineral exploration
                community
              </li>
              <li>
                Currently advising Governments & International Mining
                Institutions to develop effective exploration policies
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Leadership;
