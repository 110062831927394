import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import carouseImage1 from '../../assets/Photos/slider-1.png';
import carouseImage2 from '../../assets/Photos/slider-2.png';
import carouseImage3 from '../../assets/Photos/slider-3.png';
import carouseImage4 from '../../assets/Photos/slider-4.png';
import carouseImage5 from '../../assets/Photos/slider-5.png';
import carouseImage6 from '../../assets/Photos/slider-6.png';

const CarouselCustom = ({ items }: any) => {
  return (
    <div className="w-[100%] h-[140px] md:h-[290px]">
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={1000}
        centerMode={true}
        className=""
        containerClass="container-with-dots"
        customTransition="all 1s linear"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover={true}
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={true}
        rewindWithAnimation={true}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={2}
        swipeable
        transitionDuration={1000}
      >
        <div>
          <img loading="lazy" src={carouseImage1} alt="Carousel 1" />
        </div>
        <div>
          <img loading="lazy" src={carouseImage2} alt="Carousel 1" />
        </div>
        <div>
          <img loading="lazy" src={carouseImage3} alt="Carousel 1" />
        </div>
        <div>
          <img loading="lazy" src={carouseImage4} alt="Carousel 1" />
        </div>
        <div>
          <img loading="lazy" src={carouseImage5} alt="Carousel 1" />
        </div>
        <div>
          <img loading="lazy" src={carouseImage6} alt="Carousel 1" />
        </div>
      </Carousel>
    </div>
  );
};

export default CarouselCustom;
