import { useEffect, useState } from 'react';
import secondImage from '../../assets/Photos/2.png';
import thirdImage from '../../assets/Photos/3.png';
import fourthImage from '../../assets/Photos/4.png';
import fifthImage from '../../assets/Photos/5.png';
import sixthImage from '../../assets/Photos/6.png';
import sevenImage from '../../assets/Photos/7.png';
import eighthImage from '../../assets/Photos/8.png';
import ninthImage from '../../assets/Photos/9.png';
import firstImage from '../../assets/Photos/fixed-wing-gravity.png';
import scalerImage2 from '../../assets/Photos/SG2.png';
import PageHeading from '../../components/PageHeading/PageHeading';
import { useLocation } from 'react-router-dom';
import HRM1 from '../../assets/Photos/HRM1.jpg';
import HRM2 from '../../assets/Photos/HighResolutionMagneticImageTwo.png';
import HRM3 from '../../assets/Photos/HRM2.jpg';

import GRS1 from '../../assets/Photos/GRS1.jpg';
import GRS2 from '../../assets/Photos/GRS2.png';
import GRS3 from '../../assets/Photos/GRS3.jpg';

import SG from '../../assets/Photos/SG1.jpg';

import FTG1 from '../../assets/Photos/FTG1.jpg';
import FTG2 from '../../assets/Photos/FTG2.png';
import FTG3 from '../../assets/Photos/FTG3.png';

import TDEM1 from '../../assets/Photos/TDEM1.png';
import TDEM2 from '../../assets/Photos/TDEM2.png';

import SAM1 from '../../assets/Photos/SAM1.png';
import SAM2 from '../../assets/Photos/SAM2.png';

import PTP1 from '../../assets/Photos/PTP1.png';
import PTP2 from '../../assets/Photos/PTP_2.png';

import GG1 from '../../assets/Photos/GG1.png';
import GG2 from '../../assets/Photos/GG2.png';
import GG3 from '../../assets/Photos/GG3.png';
import GG4 from '../../assets/Photos/GG4.png';
import GG5 from '../../assets/Photos/GG5.png';

import GG6 from '../../assets/Photos/GG6.png';
import GG7 from '../../assets/Photos/GG7.jpg';

import PA1 from '../../assets/Photos/PA1.png';
import PA2 from '../../assets/Photos/PA2.png';
import PA3 from '../../assets/Photos/PA3.png';

import FTMG from '../../assets/Photos/FTMG.png';
import FTMG_2 from '../../assets/Photos/FTMG_2.png';

import OfficialLogo from '../../assets/Icons/OfficialLogo';
import Popover from '../../components/Popover';
import { Link } from 'react-router-dom';
import DropdownIcon from '../../assets/Icons/DropdownArrow';

function Technologies() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const location = useLocation(); // Use the useLocation hook
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const technologyIndex = params.get('technology');

    if (technologyIndex) {
      const index = parseInt(technologyIndex, 10); // Convert to number
      if (!isNaN(index) && index >= 0 && index < technologies.length) {
        setActiveIndex(index); // Set the activeIndex if the index is valid
      }
    }
  }, [location.search]);

  const technologies = [
    'High Resolution Magnetics',
    'Gamma-Ray-Spectrometry',
    'Scaler Gravity',
    'Full Tensor Gravity (FTG)',
    'Full Tensor Magnetic Gradiometry (FTMG)',
    'Time Domain Electromagnetics (TDEM)',
    'Sub Audio Magnetics (SAM)',
    'Passive Transient Pulse - Electromagnetics',
    'Ground Geophysics',
    'Prospectivity Analysis',
  ];

  const technologiesPictures = [
    firstImage,
    secondImage,
    thirdImage,
    fourthImage,
    fifthImage,
    sixthImage,
    sevenImage,
    eighthImage,
    ninthImage,
  ];

  const technologyDescription = [
    //1ST ELEMENT
    <div>
      <span className="font-semibold pb-4">
        Airborne magnetic surveys map the variation of the Earth's magnetic
        field strength caused by magnetic minerals in the Earth's upper crust.
      </span>
      <div className="flex gap-2 m-2">
        <div className="p-1 rounded-md bg-white">
          <img loading="lazy" src={HRM3} height={380} width={380} />
        </div>
        <div className="p-1 rounded-md bg-white">
          <img loading="lazy" src={HRM2} height={300} width={340} />
        </div>
        <div className="p-1 rounded-md bg-white">
          <img loading="lazy" src={HRM1} height={420} width={420} />
        </div>
      </div>
      <p className="font-semibold pb-4">
        The Earth's magnetic field is directly influenced by geological
        structures, geological composition and magnetic minerals, most often due
        to changes in the percentage of magnetite in the rock. Developments in
        instrumentation and data processing techniques can map sedimentary basin
        structure and lithology, geological faults, lineaments and fracture
        systems, and magnetic anomaly targets.
      </p>

      <p className="font-semibold pb-4">
        Airborne magnetic surveys are an important component in mineral,
        hydrocarbon, coal and geothermal exploration projects.
      </p>

      <p className="font-semibold pb-4">
        We have a variety of fixed wing and helicopter platforms available to
        suit any job, regardless of location or size. Surveys are conducted to
        gather the best possible data for the client whilst employing the safest
        and most practical methods of data gathering.
      </p>
    </div>,
    //2ND ELEMENT
    <div>
      <p className="font-semibold pb-4">
        Airborne gamma ray spectroscopy (radiometrics) measures the radioactive
        emissions from the soil surface which results from the natural decay of
        isotopes in the soil.
      </p>

      <div className="flex gap-2 m-2">
        <div className="p-1 rounded-md bg-white">
          <img loading="lazy" src={GRS1} height={320} width={320} />
        </div>
        <div className="p-1 rounded-md bg-white">
          <img loading="lazy" src={GRS2} height={390} width={390} />
        </div>
        <div className="p-1 rounded-md bg-white">
          <img loading="lazy" src={GRS3} height={180} width={180} />
        </div>
      </div>
      <p className="font-semibold pb-4">
        Airborne radiometric measurements have traditionally been obtained for
        four energy bands. These bands are indicative of total radiometric count
        and uranium, thorium and potassium concentrations. The data is usually
        obtained at an elevation between 40 and 150m above ground level and a
        measurement is recorded every 50 - 60 metres along flight lines. Final
        data resolution is primarily determined by survey line spacing and
        elevation.
      </p>
    </div>,
    //3RD ELEMENT
    <div>
      <div className="md:flex md:gap-12 mb-3">
        <img loading="lazy" src={SG} height={100} width={300} />
        <img loading="lazy" src={scalerImage2} height={400} width={400} />
      </div>
      <p className="font-semibold pb-4">
        Airborne gravity surveys have been successfully used in the following
        applications:
      </p>

      <ul className="list-disc ml-6  font-medium">
        <li>Petroleum exploration</li>
        <li>Geothermal resource mapping</li>
        <li>Mapping geological structure and lithology</li>
        <li>Geophysical survey mapping</li>
        <li>Engineering projects</li>
        <li>Base metals exploration</li>
      </ul>
    </div>,

    //4TH ELEMENT FTG
    <div>
      <div>
        <img
          loading="lazy"
          src={FTG1}
          height={600}
          width={600}
          className="rounded-md"
        />
      </div>
      <p className="font-semibold pb-4 mt-3">
        FTG is a state-of-the-art US military technology licensed to just 3
        global companies for the purpose of the exploration surveys. Proven in
        large-scale hydrocarbon exploration in new frontiers like India, it
        complements traditional seismic methods, saving time, money, and
        significantly reducing the carbon footprint.
      </p>
      <div className="flex gap-2">
        <div>
          <img loading="lazy" src={FTG2} />
        </div>
        <div>
          <img loading="lazy" src={FTG3} />
        </div>
      </div>
    </div>,
    //5th
    <div>
      <div>
        <div className="md:flex md:gap-4 mb-4">
          <img loading="lazy" src={FTMG} className="rounded-md" />
          <img loading="lazy" src={FTMG_2} className="rounded-md" />
        </div>

        <ul className="list-disc ml-6 ml-6 font-medium">
          <li>
            Uses the world's most sensitive SQUID sensors for
            ultra-high-resolution data
          </li>
          <li>
            Provides detailed and reliable mineralization exploration
            information
          </li>
          <li>Reduces noise, enhancing data sensitivity and accuracy</li>
          <li>
            High sensitivity at low frequencies, ideal for deep mineralization
            detection
          </li>
          <li>
            Suitable for detecting a wide range of critical minerals, including
            Lithium, Uranium, Copper, Nickel, and Gold
          </li>
        </ul>
      </div>
    </div>,
    //6TH ELEMENT
    <div>
      <p className="font-semibold pb-4">
        Airborne EM has been responsible for the discovery of billions of
        dollars worth of mineral deposits. It is a cost effective method for
        scanning large areas of ground for conductors for follow up ground
        targeting or direct target drilling. It is a preferred method for
        mapping massive sulphides and paleochannels that may contain uranium
        deposits and/or groundwater.
      </p>
      <div className="md:flex">
        <div>
          <img loading="lazy" src={TDEM1} height={100} width={500} />
        </div>
        <div>
          <img
            loading="lazy"
            src={TDEM2}
            height={80}
            width={500}
            className="h-[360px]"
          />
        </div>
      </div>
      <p className="font-semibold pb-4">
        EM surveys map the three dimensional variation in conductivity, caused
        by changes in mineralogy, intensity of alteration, water content or
        salinity. Airborne electromagnetic surveys are useful in investigations
        of different geological eras. For instance, they can help in locating
        subglacial valleys, which helps to understand water reservoirs.
        Electro-magnetic surveys are aimed at conducting a quick and cheap
        search for metallic conductors. It is also applicable to general
        geologic mapping and many engineering problems.
      </p>
      <p className="font-semibold pb-4">
        The results from the surveys can be used to make three-dimensional
        models of the conductivity and associated geology.
      </p>
    </div>,

    //6th element
    <div>
      <div className="flex">
        <div>
          <img loading="lazy" src={SAM1} />
        </div>
        <div>
          <div>
            <img loading="lazy" src={SAM2} />
          </div>
        </div>
      </div>
      <p className="font-semibold pb-8">
        HeliSAM refers to airborne acquisition using a helicopter. HeliSAM is
        used commercially for large dipole (up to 12 km) SAM MMR / MMC surveys
        and for large scale SAM EM surveys. The total field sensor simplifies
        data collection with no orientation needed, excels in mapping
        high-conductance bodies, and is ideal for deep penetration surveys and
        regional characterization, offering a comprehensive geological overview.
      </p>
      <p className="font-semibold pb-8">
        The HeliSAM system consists of the Cs sensor, GPS unit and laser
        altimeter mounted in a towed “bird”. The bird is towed with a sling to
        mitigate interference from the helicopter. The sling length is typically
        30m. No compensation is required for aircraft pitch, roll or yaw.
        HeliSAM is used for large scale SAM MMR/MMC and FLEM surveys and
        typically uses Tx frequencies 3.125-15Hz. Achievable sample intervals
        are nominally 5.0m for TMI; 20m for MMR and TFEM (depending on Tx
        frequency). <br></br> Low frequency (LF) HeliSAM is a variant of HeliSAM
        where transmit frequencies as low as 2Hz are used. LF HeliSAM uses a 50m
        sling which enables the helicopter to fly at higher elevation above
        ground and at a slower speed
      </p>
    </div>,

    //7TH ELEMENT
    <div>
      <p className="font-semibold pb-4">
        The AEM-PTP technology measures a geophysical response to reduction and
        oxidation (REDOX) activity associated with upward fluid flow. This is
        often the result of outgassing from a hydrocarbon accumulation or in
        response to the upward flow of hydrothermal fluids (e.g. mineral
        deposits and geothermal).
      </p>

      <div className="md:flex">
        <img loading="lazy" src={PTP1} width={600} height={100} />
        <img loading="lazy" src={PTP2} />
      </div>

      <p className="font-semibold pb-4">
        The technique is effectively an 'E' field version of the audio frequency
        magnetics (AFMAG) system. Consequently, this patented technology is very
        portable and lightweight only requiring a small 'E' field antenna that
        can be deployed in a wide range of survey vehicles.
      </p>
    </div>,

    //8th element
    <div>
      <p className="font-semibold pb-4">
        Similar to airborne magnetic surveys, land magnetic methods are a
        cost-effective ground geophysical survey technique. They are used for
        measuring the strength of the Earth’s magnetic field and are suitable
        for applications where a high level of detail is required.
      </p>

      <p className="font-semibold pb-4">
        While airborne magnetic data provides superb quality and is faster to
        acquire, ground magnetic surveys can provide a higher resolution due to
        tighter line spacing and the shorter distance to the source.
      </p>

      <p className="font-semibold pb-4">
        The higher accuracy and tighter line spacing are especially important
        for detecting shallow faulting, bedding, and other subsurface
        discontinuities where the presence of magnetic minerals and their
        associated rock types, in contrast with surrounding sedimentary rock,
        produces a measurable signal.
      </p>

      <div className="md:flex md:gap-4">
        <img loading="lazy" src={GG1} height={400} width={400} />
        <img loading="lazy" src={GG5} height={400} width={400} />
      </div>

      <p className="font-semibold pb-4">
        <span className="font-black	underline">GROUND GRAVITY SURVEYS</span>{' '}
        <br /> Ground gravity survey methods are used to effectively measure
        very subtle subsurface density changes in rocks. They provide very
        detailed information in complex settings where other methods might fail
        to satisfactorily resolve the problem.
      </p>

      <p className="font-semibold pb-4">
        From structures containing hydrocarbons to metal ore deposits, salt
        domes, sediment thicknesses and hydrogeological aquifers, the size and
        location of numerous geological structures can be determined using this
        method of surveying.
      </p>

      <p className="font-semibold pb-4">
        The fact that the gravity field intensity rapidly decreases with
        distance from the source means that ground methods can provide
        unrivalled resolution to address complex and shallow objectives. In
        these instances, the use of airborne surveys is not advisable due to the
        small size of the area of interest or other logistic reasons.
      </p>

      <p className="font-semibold pb-4">
        The ground gravity method also allows several configurations,
        geometries, and line spacings that can be tailored to suit the needs of
        the survey.
      </p>

      <p className="font-semibold pb-4">
        <span className="font-black underline	">MAGNETOTELLURICS (MT):</span>
        <br />
        Magnetotellurics (MT) has a broad range of applications, including
        natural resource exploration for groundwater, minerals, and geothermal.
        The depth of investigation for an MT survey ranges from 10’s of meters
        to multiple kilometers, as shown in the example in the Olympic dam below
        (60Km).
      </p>

      <div className="flex gap-2">
        <div>
          <img loading="lazy" src={GG2} />
        </div>
        <div>
          <img loading="lazy" src={GG3} />
        </div>
        <div>
          <img loading="lazy" src={GG4} />
        </div>
      </div>
      <p className="font-semibold pb-4">
        <span className="font-black	underline">GALVANIC SAM:</span> <br />{' '}
        Suitable for structural mapping, this approach considers multiple
        orientations to maximize structural information. It effectively
        identifies pathways of least resistance and tracks flows around
        resistive features.
      </p>
      <div className="md:flex md:gap-4">
        <img loading="lazy" src={GG7} className="rounded-md" />
      </div>
    </div>,

    //10th
    <div>
      <p className="font-semibold pb-4">
        Machine Learning: Fast, objective, and scalable, our machine learning
        algorithms adapt seamlessly to various datasets, processing large
        volumes of data quickly and accurately with minimal prior information.
      </p>
      <p className="font-semibold pb-4">
        Artificial Intelligence: We use AI to enhance mineral prospectivity
        analysis, identifying exploration targets with high accuracy and
        improving decision-making by interpreting complex geological data.
      </p>
      <img loading="lazy" src={PA1} />
      <div className="flex gap-2">
        <div className="flex flex-col items-center justify-center">
          <div>
            <img loading="lazy" src={PA2} />
          </div>
          <div className="text-center">
            <span className="text-primary font-semibold">
              SOM CLUSTERS PROPRIETARY - 15 MINUTES
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div>
            <img loading="lazy" src={PA3} />
          </div>
          <div className="text-center">
            <span className="text-primary font-semibold text-[12px] md:text-[18px]">
              CNN CLASSIFICATION PROPRIETARY
            </span>
          </div>
        </div>
      </div>
    </div>,
  ];

  const handleItemClick = (index: number) => {
    setActiveIndex(index);
  };
  return (
    <div className="mb-12">
      <div className="md:hidden w-fit mx-auto mt-12 bg-white bg-opacity-30 p-2 text-[12px] rounded-lg">
        <Popover
          isVisible={popoverVisible}
          setIsVisible={setPopoverVisible}
          content={
            <div>
              {technologies.map((tech, index) => (
                <Link
                  key={index}
                  to={`/our-technologies?technology=${encodeURIComponent(
                    index
                  )}`}
                  className="md:hidden block p-2 hover:bg-gray-100"
                  onClick={() => setPopoverVisible(false)} // Close popover on item click
                >
                  {tech}
                </Link>
              ))}
            </div>
          }
        >
          <div className="md:hidden flex gap-1 justify-center items-center">
            <span
              className={
                location.pathname === '/our-technologies'
                  ? 'nav-active hover:text-white-600 text-navbar-title text-[16px]'
                  : 'nav-inactive text-navbar-title hover:text-secondary-light text-[12px]'
              }
            >
              Technologies
            </span>
            <DropdownIcon
              color="black"
              opacity={
                location.pathname === '/our-technologies' ? '' : 'opacity-60'
              }
            />
          </div>
        </Popover>
      </div>

      <div className="mx-6 md:mx-24  mt-4 md:mt-12 flex flex-row gap-20">
        <div className="hidden md:flex flex-col md:w-[20%]">
          <div>
            {technologies.map((technique, index) => (
              <div
                key={index}
                onClick={() => handleItemClick(index)}
                className={`py-4 px-4 rounded-md max-w-66 mt-2 cursor-pointer font-medium bg-white text-primary border-primary border-2 ${
                  activeIndex === index
                    ? 'bg-white text-primary border-primary border-2' // Active state: white background, black text
                    : 'bg-white bg-opacity-30 text-primary border-white border-2' // Default state
                }`}
              >
                {technique}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col md:w-[80%]">
          <div>
            {/* TODO: make common component 
            <Title
              text={technologies[activeIndex || 0]}
              weight="font-bold"
              size={2}
            /> */}
            <div className={'text-primary font-bold text-[28px]'}>
              {technologies[activeIndex || 0]}
            </div>
          </div>

          <div className="pt-8 pb-4">
            {technologyDescription[activeIndex || 0]}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Technologies;
