import React from 'react';
import Title from '../../components/Title';
import Phone from '../../assets/Icons/Phone';
import Location from '../../assets/Icons/Location';
import Work from '../../assets/Icons/Work';
import PageHeading from '../../components/PageHeading/PageHeading';
import ContacusForm from '../../components/ContactUsForm';

function ContactUs() {
  return (
    <div className="mb-12">
      <div className="rounded-md bg-blue-50 mt-12 flex justify-center items-center w-[312px] md:w-[1050px] mx-auto">
        <iframe
          src="https://www.google.com/maps/d/u/6/embed?mid=1SzfwFH0c2PJMpFWcrQ13BH5TfrWLoxo&ehbc=2E312F"
          width="100%"
          height="480"
        ></iframe>
      </div>
      <div className="mx-4">
        <ContacusForm />
      </div>
    </div>
  );
}

export default ContactUs;
