import React from 'react';
import Title from '../../components/Title';
import AboutUsPics from '../../assets/Photos/AboutUsPics.png';
function AboutUs() {
  return (
    <div>
      <div className="hidden md:flex mt-20 mb-12 md:mx-6 items-center justify-center">
        <img loading="lazy" src={AboutUsPics} className="w-fit h-[620px]" />
      </div>
      <div className="md:hidden">
        <img
          loading="lazy"
          src={AboutUsPics}
          className="px-6 py-6 pt-16 pb-8"
        />
      </div>

      <div className="mb-16 mx-6 md:mx-40 font-medium font-sans">
        <p>
          Airborne Geoscience International (AGI) is an Indian startup mandated
          to become India's first full service early-stage natural resource
          exploration company. AGI is currently in the process of developing its
          business infrastructure and establishing its business footprint across
          four continents internationally. At this time, AGI and its associates
          have executed exploration survey contracts for various Governments
          worldwide covering more than 2.5 million line kms.
          <br />
          <br />
          Early-stage exploration of subsurface natural resources includes:
          <ul className="list-disc pl-5 mt-3 mb-3">
            <li className="pb-1">
              Base and Precious metals (Copper, Lead, Zinc, Platinum, Gold,
              Silver, etc.)
            </li>
            <li className="pb-1">
              Critical and Radioactive minerals (Titanium, Uranium, Potassium,
              etc.)
            </li>
            <li className="pb-1">
              Hydrocarbons (Oil and Gas, Coalbed, Methane, etc.)
            </li>
            <li className="pb-1">Kimberlites (Diamonds)</li>
            <li className="pb-1">
              Underground water and Geothermal sources, etc.
            </li>
          </ul>
          The above resources involve application of a wide range of airborne
          and ground technologies over large unexplored acreage, with the aim to
          identify and establish the discoveries of deep-seated natural resource
          deposits, in the most comprehensive, time efficient and cost-effective
          manner.
          <br />
          <br />
          Given the above background, AGI is in the process of developing early
          stage exploration infrastructure in India, which will form the bedrock
          for India's “Atmanirbhar Exploration” of critical and deep seated
          natural resources. Development of such infrastructure includes, but is
          not limited to, development and/or application of state-of-the-art,
          exclusive and patented survey technologies, survey aircraft and
          equipment, suitably qualified human resources, early stage exploration
          consulting, data management including data analysis etc in India, with
          a goal to make India the largest global exploration hotspot of the
          future.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
