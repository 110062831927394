import React from 'react';
import './style.css';
import HeroImage from '../../assets/Photos/HeroImage.png';

const HeroSection = () => {
  return (
    <section className="hero-section flex flex-col md:flex-row mt-8">
      <div className="hero-content">
        <h1 className="font-medium text-lg md:text-4xl md:leading-relaxed hero-title">
          India’s FIRST AND ONLY technology aggregator for airborne exploration
          of subsurface natural resources, including critical and deep seated
          minerals, hydrocarbons, groundwater and geothermal sources
        </h1>
      </div>
      <div>
        {/* Carousel component or images */}
        <img
          loading="lazy"
          src={HeroImage}
          alt="Exploration Aircraft"
          height={18}
        />
      </div>
    </section>
  );
};

export default HeroSection;
